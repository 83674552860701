<template>
  <configuration-set
    v-if="!!getConfigurationSet()?.courier"
    :components="components"
    :speditor="speditor"
    :current-component="currentComponent"
    :is-side-bar-active="isAuthorized"
    :loading="loading"
    @@changeComponent="changeComponent"
    @@save="save"
  >
    <template v-slot:content>
      <ServiceAuthorization
        v-show="currentComponent === 'ServiceAuthorization'"
        :is-new="isNew"
        :is-authorized="isAuthorized"
      />

      <AdditionalServices
        v-show="currentComponent === 'AdditionalServices'"
        :is-new="isNew"
      />

      <LabelSettings
        v-show="currentComponent === 'LabelSettings'"
        :is-new="isNew"
      />

      <ContentAndComments
        v-show="currentComponent === 'ContentAndComments'"
        :is-new="isNew"
      />
    </template>
  </configuration-set>
</template>

<script>
import ConfigurationSet from '@/components/shared/ConfigurationSet.vue';
import { UPS } from '@/constants/Speditors/UPS/configuration/UPS.js';
import configurationMixin from '@/mixins/configurationMixin';
import ServiceAuthorization from '@/views/Speditors/UPS/Forms/ServiceAuthorization.vue';
import AdditionalServices from '@/views/Speditors/UPS/Forms/AdditionalServices.vue';
import LabelSettings from '@/views/Speditors/UPS/Forms/LabelSettings.vue';
import ContentAndComments from '@/views/Speditors/UPS/Forms/ContentAndComments.vue';

export default {
  name: 'UPS',
  components: {
    ConfigurationSet,
    ServiceAuthorization,
    AdditionalServices,
    LabelSettings,
    ContentAndComments,
  },
  mixins: [configurationMixin],
  data: () => ({
    isNew: false,
    speditor: 'UPS',
    organizationId: null,
    currentComponent: 'ServiceAuthorization',
    components: [
      { code: 'ServiceAuthorization', name: 'courierSet.serviceAuthorizationName' },
      { code: 'AdditionalServices', name: 'courierSet.additionalServices' },
      { code: 'LabelSettings', name: 'courierSet.labelSettings' },
      { code: 'ContentAndComments', name: 'courierSet.contentAndComments' },
    ],
  }),
  computed: {
    isAuthorized() {
      return !!this.getConfigurationSet().token;
    },
  },
  methods: {
    setNewConfiguration() {
      this.setConfigSet({ response: UPS });
    },
  },
};
</script>
