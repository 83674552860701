export const upsDefaultValues = {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  packageCode: '',
  dimension: {},
  declaredValue: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    code: '',
    description: '',
    currencyCode: '',
    amount: 0,
  },
};
