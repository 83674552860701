<template>
  <div>
    <form-section :title="$t('courierSet.prcelContent')">
      <v-row>
        <v-col>
          <text-area-field
            v-model="getDeclaredValue.description"
            :title="$t('courierSet.parcelDescription')"
            rules="max:165"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    isNew: { type: Boolean, default: null },
  },
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDeclaredValue() {
      return this.getConfigurationSet().upsDefaultValues.declaredValue;
    },
  },
};
</script>

<style scoped></style>
