export const labelFormats = [
  {
    id: 'EPL',
    name: 'Format EPL2',
  },
  {
    id: 'SPL',
    name: 'Format SPL',
  },
  {
    id: 'ZPL',
    name: 'Format ZPL',
  },
  {
    id: 'GIF',
    name: 'Format dla obrazów',
  },
];
