export const upsAdditionalServices = [
  {
    id: 1,
    serviceId: 'COD',
    name: 'Pobranie',
    description: 'Pobranie za przesyłkę.',
    parameters: true,
    remark: '',
  },
  {
    id: 2,
    serviceId: 'INSURANCE',
    name: 'Ubezpieczenie',
    description: 'Ubezpieczenie przesyłki.',
    parameters: true,
    remark: '',
  },
  {
    id: 115,
    serviceId: 'SATURDAY_DELIVERY',
    name: 'Dostawa w sobotę',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 121,
    serviceId: 'PICKUP_ON_SATURDAY',
    name: 'Nadanie w sobotę',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 200,
    serviceId: 'EMAIL_NOTIFICATION_SENDER',
    name: 'Powiadomienie email nadawcy',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 201,
    serviceId: 'EMAIL_NOTIFICATION_RECEIVER',
    name: 'Powiadomienie email odbiorcy',
    description: '',
    parameters: false,
    remark: '',
  },
  {
    id: 700,
    serviceId: 'PARCEL_VALUE',
    name: 'Wartość paczki',
    description: '',
    parameters: false,
    remark: '',
  },
];
