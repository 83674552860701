export const serviceTypes = [
  { id: '01', name: 'Next Day Air' },
  { id: '02', name: '2nd Day Air' },
  { id: '03', name: 'Ground' },
  { id: '07', name: 'Express' },
  { id: '08', name: 'Expedited' },
  { id: '11', name: 'UPS Standard' },
  { id: '12', name: '3 Day Select' },
  { id: '13', name: 'Next Day Air Saverv' },
  { id: '14', name: 'UPS Next Day Air® Early' },
  { id: '17', name: 'UPS Worldwide Economy DDU' },
  { id: '54', name: 'Express Plus' },
  { id: '59', name: '2nd Day Air A.M.' },
  { id: '65', name: 'UPS Saver' },
  { id: 'M2', name: 'First Class Mail' },
  { id: 'M3', name: 'Priority Mail' },
  { id: 'M4', name: 'Expedited MaiI Innovations' },
  { id: 'M5', name: 'Priority Mail Innovations' },
  { id: 'M6', name: 'Economy Mail Innovations' },
  { id: 'M7', name: 'MaiI Innovations (MI) Returns' },
  { id: '70', name: 'UPS Access Point™ Economy' },
  { id: '71', name: 'UPS Worldwide Express Freight Midday' },
  { id: '72', name: 'UPS Worldwide Economy DDP' },
  { id: '74', name: 'UPS Express®12:00' },
  { id: '75', name: 'UPS Heavy Goods' },
  { id: '82', name: 'UPS Today Standard' },
  { id: '83', name: 'UPS Today Dedicated Courier' },
  { id: '84', name: 'UPS Today Intercity' },
  { id: '85', name: 'UPS Today Express' },
  { id: '86', name: 'UPS Today Express Saver' },
  { id: '96', name: 'UPS Worldwide Express Freight' },
];
